export const styles = `
.flipdown.flipdown__theme-hidden {
    display: none;
}
.flipdown.flipdown__theme-light {
    width: 202px;
    height: 41px;
    display: flex;
    justify-content: center;
    align-items: center;
    .rotor {
        font-size: 14px;
        line-height: 85%;
        font-weight: 900;
        font-family: Inter, sans-serif;
        border-radius: 2px;
    }
    .rotor-group {
        margin-right: 11px;
        padding-right: 0;
        width: 28px;
        display: flex;
        align-items: center;
    }
    .rotor-group-heading {
        display: none;
    }
    .rotor, .rotor-leaf, .rotor-leaf-front, .rotor-leaf-rear, .rotor-top, .rotor-bottom, .rotor:after {
        width: 17px;
    }
    .rotor, .rotor-top, .rotor-leaf-front, .rotor-bottom, .rotor-leaf-rear {
        background-color: #FFFFFF;
        color: #101010;
    }
    .rotor-group:nth-child(n+2):nth-child(-n+3):before, .rotor-group:nth-child(n+2):nth-child(-n+3):after {
        background-color: #FFFFFF;
        left: 41px;
        width: 3px;
        height: 3px;
    }
    .rotor-group:nth-child(n+2):nth-child(-n+3):before {
        bottom: 10px;
    }
    .rotor-group:nth-child(n+2):nth-child(-n+3):after {
        bottom: 17px;
    }
    .rotor-group-heading:before {
        color: #101010;
        font-size: 14px;
        font-weight: 900;
        line-height: 12px;
        height: fit-content;
    }
    .rotor-leaf-front, .rotor-top {
        line-height: 30px;
    }
    .rotor-top {
        border-radius: 2px 2px 0 0;
    }
    .rotor-bottom {
        border-radius: 0 0 2px 2px;
    }
    .rotor-leaf, .rotor {
        height: 30px;
    }
    .rotor-leaf-front, .rotor-leaf-rear, .rotor-top, .rotor-bottom, .rotor:after {
        height: 15px;
    }
    .rotor-group:nth-child(n+3):nth-child(-n+3) {
        margin: 0;
    }
    .rotor-group:nth-child(n+2):nth-child(-n+3) {
        margin-right: 21px;
    }
    .rotor-group:first-child {
        width: 51px;
        margin-right: 14px;
    }
    .rotor-group:nth-child(1):before, .rotor-group:nth-child(1):after {
        content: '';
        position: absolute;
        background-color: #FFFFFF;
        left: 57px;
        width: 3px;
        height: 3px;
        border-radius: 50%;
    }
    .rotor-group:nth-child(1):before {
        bottom: 10px;
    }
    .rotor-group:nth-child(1):after {
        bottom: 17px;
    }
    .rotor-group:first-child .rotor-group-heading {
        display: flex;
        align-items: center;
        order: 3;
        padding-left: 8px;
        background-color: #FFFFFF;
        color: black;
        height: 30px;
        border-radius: 2px;
        padding-right: 7px;
    }
    .rotor-group:first-child .rotor-group-heading:after {
        content: '';
        height: 15px;
        width: 17px;
        border-bottom: 1px solid #94949466;
        background: transparent;
        position: absolute;
        top: 1px;
        right: 0;
    }
    .rotor:after {
        border-top: 1px solid #94949466;
        border-radius: 0 0 2px 2px;
        width: 20px;
    }
    .rotor-group:first-of-type > div:nth-child(2) {
        padding-left: 5px;
        padding-right: 8px;
    }
    .rotor-group:nth-child(2) > .rotor:nth-child(2), .rotor-group:nth-child(3) > .rotor:nth-child(2), .rotor-group:nth-child(4) > .rotor:nth-child(2) {
        padding-left: 4px;
        padding-right: 9px;
    }
    .rotor-group:nth-child(2) > .rotor:nth-child(3), .rotor-group:nth-child(3) > .rotor:nth-child(3), .rotor-group:nth-child(4) > .rotor:nth-child(3) {
        padding-left: 0;
        padding-right: 20px;
    }
}
.flipdown.flipdown__theme-blackfriday-shelf {
    width: 134px;
    height: 41px;
    display: flex;
    justify-content: center;
    align-items: center;
    .rotor {
        font-size: 14px;
        line-height: 85%;
        font-weight: 900;
        font-family: Inter, sans-serif;
        border-radius: 2px;
    }
    .rotor-group {
        margin-right: 11px;
        padding-right: 0;
        width: 28px;
        display: flex;
        align-items: center;
    }
    .rotor-group-heading, .rotor-group:first-child {
        display: none;
    }
    .rotor, .rotor-leaf, .rotor-leaf-front, .rotor-leaf-rear, .rotor-top, .rotor-bottom, .rotor:after {
        width: 17px;
    }
    .rotor, .rotor-top, .rotor-leaf-front, .rotor-bottom, .rotor-leaf-rear {
        background-color: #FFFFFF;
        color: #101010;
    }
    .rotor-group:nth-child(n+2):nth-child(-n+3):before, .rotor-group:nth-child(n+2):nth-child(-n+3):after {
        background-color: #FFFFFF;
        left: 41px;
        width: 3px;
        height: 3px;
    }
    .rotor-group:nth-child(n+2):nth-child(-n+3):before {
        bottom: 10px;
    }
    .rotor-group:nth-child(n+2):nth-child(-n+3):after {
        bottom: 17px;
    }
    .rotor-leaf-front, .rotor-top {
        line-height: 30px;
    }
    .rotor-top {
        border-radius: 2px 2px 0 0;
    }
    .rotor-bottom {
        border-radius: 0 0 2px 2px;
    }
    .rotor-leaf, .rotor {
        height: 30px;
    }
    .rotor-leaf-front, .rotor-leaf-rear, .rotor-top, .rotor-bottom, .rotor:after {
        height: 15px;
    }
    .rotor-group:nth-child(n+3):nth-child(-n+3) {
        margin: 0;
    }
    .rotor-group:nth-child(n+2):nth-child(-n+3) {
        margin-right: 21px;
    }
    .rotor-group:first-child {
        width: 51px;
        margin-right: 14px;
    }
    .rotor-group:nth-child(1):before, .rotor-group:nth-child(1):after {
        content: '';
        position: absolute;
        background-color: #FFFFFF;
        left: 57px;
        width: 3px;
        height: 3px;
        border-radius: 50%;
    }
    .rotor-group:nth-child(1):before {
        bottom: 10px;
    }
    .rotor-group:nth-child(1):after {
        bottom: 17px;
    }
    .rotor:after {
        border-top: 1px solid #94949466;
        border-radius: 0 0 2px 2px;
        width: 20px;
    }
    .rotor-group:first-of-type > div:nth-child(2) {
        padding-left: 5px;
        padding-right: 8px;
    }
    .rotor-group:nth-child(2) > .rotor:nth-child(2), .rotor-group:nth-child(3) > .rotor:nth-child(2), .rotor-group:last-of-type > .rotor:nth-child(2) {
        padding-left: 4px;
        padding-right: 9px;
    }
    .rotor-group:nth-child(2) > .rotor:nth-child(3), .rotor-group:nth-child(3) > .rotor:nth-child(3), .rotor-group:nth-child(4) > .rotor:nth-child(3) {
        padding-left: 0;
        padding-right: 20px;
    }
}
@media screen and (min-width: 551px) {
    .flipdown.flipdown__theme-light {
        .rotor-group:nth-child(n+2):nth-child(-n+3):before, .rotor-group:nth-child(n+2):nth-child(-n+3):after {
            left: 42px;
        }
        .rotor-group:nth-child(1):before, .rotor-group:nth-child(1):after {
            left: 58px;
        }
        .rotor-group:first-child .rotor-group-heading {
            padding-left: 17px;
        }
        .rotor-group:first-child .rotor-group-heading:after {
            right: -2px;
        }
    }        
    .flipdown.flipdown__theme-blackfriday-shelf {
        .rotor-group:nth-child(n+2):nth-child(-n+3):before, .rotor-group:nth-child(n+2):nth-child(-n+3):after {
            left: 42px;
        }
        .rotor-group:nth-child(1):before, .rotor-group:nth-child(1):after {
            left: 58px;
        }
        .rotor-group:first-child .rotor-group-heading {
            padding-left: 17px;
        }
        .rotor-group:first-child .rotor-group-heading:after {
            right: -2px;
        }
    }   
}
@media screen and (min-width: 900px) {
    .flipdown.flipdown__theme-light {
        width: 244px;
        .rotor-group:first-child .rotor-group-heading {
            padding-right: 9px;
            height: 40px;
        }
        .rotor-leaf, .rotor {
            height: 40px;
            font-size: 18px;
        }
        .rotor-group-heading:before {
            font-size: 18px;
            line-height: 100%;
        }
        .rotor-leaf-front, .rotor-leaf-rear, .rotor-top, .rotor-bottom, .rotor:after {
            height: 20px;
        }
        .rotor-leaf-front, .rotor-top {
            line-height: 40px;
        }
        .rotor-group:first-child .rotor-group-heading:after {
            height: 20px;
            width: 18px;
            right: -11px;
        }
        .rotor:after {
            width: 23px;
        }
        .rotor-group:first-of-type > div:nth-child(2) {
            padding-left: 8px;
            padding-right: 9px;
        }
        .rotor-group:first-child {
            margin-right: 24px;
        }
        .rotor-group:nth-child(1):before, .rotor-group:nth-child(1):after {
            left: 67px;
        }
        .rotor-group:nth-child(2) > .rotor:nth-child(2), .rotor-group:nth-child(3) > .rotor:nth-child(2), .rotor-group:nth-child(4) > .rotor:nth-child(2) {
            padding-left: 7px;
            padding-right: 10px;
        }
        .rotor-group:nth-child(2) > .rotor:nth-child(3), .rotor-group:nth-child(3) > .rotor:nth-child(3), .rotor-group:nth-child(4) > .rotor:nth-child(3) {
            padding-right: 23px;
        }
        .rotor-group:nth-child(n+2):nth-child(-n+3) {
            margin-right: 30px;
        }
        .rotor-group:nth-child(n+2):nth-child(-n+3):before, .rotor-group:nth-child(n+2):nth-child(-n+3):after {
            left: 50px;
        }
        .rotor-group:nth-child(1):before, .rotor-group:nth-child(n+2):nth-child(-n+3):before {
            bottom: 14px;
        }
        .rotor-group:nth-child(1):after, .rotor-group:nth-child(n+2):nth-child(-n+3):after {
            bottom: 22px;
        }
    }
    .flipdown.flipdown__theme-blackfriday-shelf {
        width: 172px;
        .rotor-group:first-child .rotor-group-heading {
            padding-right: 9px;
            height: 40px;
        }
        .rotor-leaf, .rotor {
            height: 40px;
            font-size: 20px;
        }
        .rotor-group-heading:before {
            font-size: 20px;
            line-height: 100%;
        }
        .rotor-leaf-front, .rotor-leaf-rear, .rotor-top, .rotor-bottom, .rotor:after {
            height: 20px;
        }
        .rotor-leaf-front, .rotor-top {
            line-height: 40px;
        }
        .rotor-group:first-child .rotor-group-heading:after {
            height: 20px;
            width: 18px;
            right: -11px;
        }
        .rotor:after {
            width: 23px;
        }
        .rotor-group:first-of-type > div:nth-child(2) {
            padding-left: 8px;
            padding-right: 9px;
        }
        .rotor-group:first-child {
            margin-right: 24px;
        }
        .rotor-group:nth-child(1):before, .rotor-group:nth-child(1):after {
            left: 67px;
        }
        .rotor-group:nth-child(2) > .rotor:nth-child(2), .rotor-group:nth-child(3) > .rotor:nth-child(2), .rotor-group:last-of-type > .rotor:nth-child(2) {
            padding-left: 7px;
            padding-right: 10px;
        }
        .rotor-group:nth-child(2) > .rotor:nth-child(3), .rotor-group:nth-child(3) > .rotor:nth-child(3), .rotor-group:nth-child(4) > .rotor:nth-child(3) {
            padding-right: 23px;
        }
        .rotor-group:nth-child(n+2):nth-child(-n+3) {
            margin-right: 30px;
        }
        .rotor-group:nth-child(n+2):nth-child(-n+3):before, .rotor-group:nth-child(n+2):nth-child(-n+3):after {
            left: 50px;
        }
        .rotor-group:nth-child(1):before, .rotor-group:nth-child(n+2):nth-child(-n+3):before {
            bottom: 14px;
        }
        .rotor-group:nth-child(1):after, .rotor-group:nth-child(n+2):nth-child(-n+3):after {
            bottom: 22px;
        }
    }
}
`
